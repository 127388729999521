import { useEffect, useState } from 'react';

/**
 * Returns true if the component has mounted, false otherwise.
 *
 * Sometimes you might have code that should only run on the client, not the server.
 * The following has historically been a common way to make sure the code only runs on the client:
 *
 * if (typeof window === 'undefined') {
 *   return null;
 * }
 *
 * Unfortunately this won't work with server-side rendering. The reason is related
 * to how hydration works. When a React app hydrates, it assumes that the DOM structure
 * will match the server-side rendered version. It is only *after* the hydration is finished
 * that we can start to differantiate between client and server.
 *
 * This hook solves the problem by returning false when the hydration happens. However, as soon as
 * the hydration is finished, the hasMounted state changes and a new render is triggered.
 *
 * For more, see https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 */
export default function useHasMounted(): boolean {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}
