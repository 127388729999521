import { useState, useEffect } from 'react';
import useHasMounted from './useHasMounted';

/*
 * If you use 100vh on an iOS device, the bottom part will often be
 * covered by the browsers bottom panel. Even worse: The browsers won't
 * let you to scroll to the part that is covered up.
 *
 * This hook can be used to solve that problem. On the server-side and
 * before the rehydration has finished, it returns '100vh'.
 * After the app has mounted, it will return the height in pixels instead
 * (window.innerHeight)
 *
 * SOURCES
 * https://github.com/mvasin/react-div-100vh
 * https://www.eventbrite.com/engineering/mobile-safari-why/
 */
const FALLBACK_HEIGHT = '100vh';

function isClient() {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

function measureHeight(): string {
  if (!isClient()) return FALLBACK_HEIGHT;
  return `${window.innerHeight}px`;
}

export default function use100vh(): string {
  const [height, setHeight] = useState(measureHeight);
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (!hasMounted) {
      return () => {};
    }

    const setMeasuredHeight = () => {
      const measuredHeight = measureHeight();
      setHeight(measuredHeight);
    };

    window.addEventListener('resize', setMeasuredHeight);

    return () => window.removeEventListener('resize', setMeasuredHeight);
  }, [hasMounted]);
  return hasMounted ? height : FALLBACK_HEIGHT;
}
